/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/novo-elements/novo-elements.ngfactory";
import * as i3 from "novo-elements";
import * as i4 from "chomsky";
import * as i5 from "@angular/common";
import * as i6 from "./sidebar-filter.component";
import * as i7 from "@angular/router";
import * as i8 from "../../services/search/search.service";
var styles_SidebarFilterComponent = [i0.styles];
var RenderType_SidebarFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarFilterComponent, data: {} });
export { RenderType_SidebarFilterComponent as RenderType_SidebarFilterComponent };
function View_SidebarFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "novo-loading", [], [[8, "className", 0]], null, null, i2.View_ɵq_0, i2.RenderType_ɵq)), i1.ɵdid(1, 49152, null, 0, i3.ɵq, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).theme || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "novo-form", [], null, null, null, i2.View_ɵdj_0, i2.RenderType_ɵdj)), i1.ɵprd(512, null, i3.NovoTemplateService, i3.NovoTemplateService, []), i1.ɵdid(2, 1163264, null, 1, i3.ɵdj, [i3.NovoTemplateService], { form: [0, "form"] }, null), i1.ɵqud(603979776, 1, { customTemplates: 1 }), (_l()(), i1.ɵeld(4, 0, null, 2, 2, "div", [["class", "novo-form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "novo-control", [], [[8, "className", 0], [1, "data-control-type", 0], [2, "disabled", null], [2, "hidden", null], [1, "data-control-key", 0]], null, null, i2.View_ɵdi_0, i2.RenderType_ɵdi)), i1.ɵdid(6, 5488640, null, 0, i3.ɵdi, [i1.ElementRef, i3.NovoLabelService, i3.ɵcy, i3.FieldInteractionApi, i3.NovoTemplateService, i1.ChangeDetectorRef, i1.LOCALE_ID], { control: [0, "control"], form: [1, "form"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 2, 0, currVal_0); var currVal_6 = _co.control; var currVal_7 = _co.form; _ck(_v, 6, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).form.controls[i1.ɵnov(_v, 6).control.key].controlType; var currVal_2 = i1.ɵnov(_v, 6).form.controls[i1.ɵnov(_v, 6).control.key].controlType; var currVal_3 = i1.ɵnov(_v, 6).form.controls[i1.ɵnov(_v, 6).control.key].readOnly; var currVal_4 = i1.ɵnov(_v, 6).form.controls[i1.ɵnov(_v, 6).control.key].hidden; var currVal_5 = i1.ɵnov(_v, 6).control.key; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_SidebarFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "filter-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarFilterComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarFilterComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loading; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("FILTER_BY")); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_SidebarFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar-filter", [], null, null, null, View_SidebarFilterComponent_0, RenderType_SidebarFilterComponent)), i1.ɵdid(1, 638976, null, 0, i6.SidebarFilterComponent, [i7.ActivatedRoute, i5.Location, i8.SearchService, i3.FormUtils], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarFilterComponentNgFactory = i1.ɵccf("app-sidebar-filter", i6.SidebarFilterComponent, View_SidebarFilterComponent_Host_0, { filter: "filter", field: "field", title: "title" }, { checkboxFilter: "checkboxFilter" }, []);
export { SidebarFilterComponentNgFactory as SidebarFilterComponentNgFactory };
