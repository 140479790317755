var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';
import { TranslateService } from 'chomsky';
import { TransferState, makeStateKey } from '@angular/platform-browser';
var APP_CONFIG_URL = './app.json';
var LANGUAGE_KEY = makeStateKey('language');
var SettingsService = /** @class */ (function () {
    function SettingsService(http, platformId, request, transferState) {
        this.http = http;
        this.request = request;
        this.transferState = transferState;
        SettingsService.isServer = isPlatformServer(platformId);
    }
    SettingsService.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(APP_CONFIG_URL).toPromise()];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, this.setConfig(data)];
                }
            });
        });
    };
    SettingsService.prototype.setConfig = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var objectConfigOptions, validTokenRegex, validSwimlaneRegex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        SettingsService.settings = data;
                        objectConfigOptions = ['service', 'additionalJobCriteria', 'integrations', 'eeoc', 'privacyConsent'];
                        objectConfigOptions.forEach(function (option) {
                            if (!SettingsService.settings[option]) {
                                SettingsService.settings[option] = {};
                            }
                        });
                        if (!SettingsService.settings.service.fields || SettingsService.settings.service.fields.length === 0) {
                            SettingsService.settings.service.fields = [
                                'id',
                                'title',
                                'publishedCategory(id,name)',
                                'address(city,state,countryName,zip)',
                                'employmentType',
                                'dateLastPublished',
                                'publicDescription',
                                'isOpen',
                                'isPublic',
                                'isDeleted',
                                'publishedZip',
                                'salary',
                                'salaryUnit',
                            ];
                        }
                        if (!SettingsService.settings.service.jobInfoChips) {
                            SettingsService.settings.service.jobInfoChips = [
                                'employmentType',
                                {
                                    type: 'mediumDate',
                                    field: 'dateLastPublished',
                                },
                            ];
                        }
                        if (!SettingsService.settings.service.keywordSearchFields || SettingsService.settings.service.keywordSearchFields.length === 0) {
                            SettingsService.settings.service.keywordSearchFields = [
                                'publicDescription',
                                'title',
                            ];
                        }
                        validTokenRegex = /[^A-Za-z0-9]/;
                        if (!SettingsService.settings.service.corpToken || validTokenRegex.test(SettingsService.settings.service.corpToken)) {
                            throw new Error('Invalid Corp Token');
                        }
                        validSwimlaneRegex = /[^0-9]/;
                        if (!SettingsService.settings.service.swimlane || validSwimlaneRegex.test(SettingsService.settings.service.swimlane.toString())) {
                            throw new Error('Invalid Swimlane');
                        }
                        if (SettingsService.urlRoot) {
                            TranslateService.setLocation(SettingsService.urlRoot + "i18n/");
                        }
                        return [4 /*yield*/, TranslateService.use(this.getPreferredLanguage()).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsService.prototype.getPreferredLanguage = function () {
        var supportedLanguages = SettingsService.settings.supportedLocales;
        var language = SettingsService.settings.defaultLocale;
        if (SettingsService.isServer) {
            language = this.request['acceptsLanguages'](supportedLanguages);
            if (!language) {
                language = SettingsService.settings.defaultLocale;
            }
            this.transferState.set(LANGUAGE_KEY, language);
        }
        else {
            language = this.transferState.get(LANGUAGE_KEY, undefined);
            if (!language) {
                language = SettingsService.settings.supportedLocales.filter(function (locale) {
                    return navigator.language === locale;
                })[0];
            }
            if (!language) {
                language = SettingsService.settings.defaultLocale;
            }
        }
        return language;
    };
    return SettingsService;
}());
export { SettingsService };
