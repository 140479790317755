<novo-loading *ngIf="loading"></novo-loading>
<app-structured-seo *ngIf="!loading" [jobData]="job"></app-structured-seo>
<div class="background" *ngIf="!loading">

    <div class="job-container">
        <div class="job-and-related-jobs">
            <div class="job">
                <button theme="dialogue" (click)="goToJobList()" icon="previous" side="left">{{ 'BACK' | translate}}</button>
                <div class="job-header">
                    <div class="job-description-header">Job Description</div>
                    <span class="job-title">{{job.title}}</span>
                    <br/>
                    <span class="category" *ngIf="job.publishedCategory && showCategory">
                        {{ job.publishedCategory?.name }}
                    </span>
                    <div class="job-info-container">
                        <span class="job-info" *ngIf="job.address.city || job.address.state">
                            <i class="bhi-location"></i>
                            {{ job.address.city }} <span *ngIf="job.address?.city && job.address?.state">,&nbsp;</span> {{ job.address.state }}
                        </span>
                        <ng-container *ngFor="let chip of jobInfoChips" [ngSwitch]="chip.type || 'string'">
                            <span class="job-info" *ngIf="job[chip] || job[chip?.field]">
                                <ng-container *ngSwitchCase="'string'">
                                    {{ job[chip?.field] || job[chip] }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'mediumDate'">
                                    {{ job[chip?.field] | date: 'mediumDate'}}
                                </ng-container>
                                <ng-container  *ngSwitchCase="'dateTime'">
                                    {{ job[chip?.field] | date: 'medium'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'USD'">
                                    {{ job[chip?.field] | currency: 'USD'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'GBP'">
                                    {{ job[chip?.field] | currency: 'GBP'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'EUR'">
                                    {{ job[chip?.field] | currency: 'EUR'}}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ job[chip?.field] || job[chip] }}
                                </ng-container>
                            </span>
                        </ng-container>
                    </div>
                </div>

                <div class="job-description-text" [innerHTML]="job.publicDescription"></div>

                <div class="share">
                    <button theme="primary" *ngIf="!alreadyApplied" (click)="apply()">{{ 'APPLY_NOW' | translate }}</button>
                    <button theme="primary" *ngIf="alreadyApplied" disabled="true">{{ 'ALREADY_APPLIED' | translate }}</button>
                    <button theme="icon" icon="email" (click)="emailLink()" *ngIf="showShareButtons"></button>
                    <button theme="icon" icon="twitter" (click)="shareTwitter()" *ngIf="showShareButtons"></button>
                    <button theme="icon" icon="facebook" (click)="shareFacebook()" *ngIf="showShareButtons"></button>
                    <button theme="icon" icon="linkedin" (click)="shareLinkedin()" *ngIf="showShareButtons"></button>
                    <button theme="dialogue" icon="share" (click)="toggleShareButtons()" *ngIf="!showShareButtons">{{ 'SHARE_POST' | translate }}</button>
                    <button theme="icon" icon="print" (click)="print()"></button>
                </div>
            </div>

            <div class="related-job-container">
                <app-related-jobs [job]="job" (onApply)=apply()></app-related-jobs>
            </div>
        </div>

    </div>
</div>
