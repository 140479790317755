import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { SearchService } from '../services/search/search.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-related-jobs',
  templateUrl: './related-jobs.component.html',
  styleUrls: ['./related-jobs.component.scss']
})
export class RelatedJobsComponent implements OnInit {
  @Input() job: any = {};
  @Output() onApply: EventEmitter<any> = new EventEmitter();

  relatedJobs = [];
  loading = false;

  constructor(
    private router: Router,
    private searchService: SearchService
  ) { }

  ngOnInit() {
    this.getRelatedJobs(this.job.publishedCategory.id);
  }

  openJob(jobId: string) {
    this.router.navigate([`jobs/${jobId}`]);
  }

  apply() {
    this.onApply.emit();
  }

  viewAllJobs() {
    this.router.navigate([`/`], {
      queryParams: { 'publishedCategory': this.job.publishedCategory.id },
    });
  }

  getRelatedJobs(publishedId: string) {
    let filter = {
      publishedId: [`publishedCategory.id{?^^equals}${publishedId}`]
    };
    this.loading = true;

    this.searchService.getjobs(filter, {start: 0})
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        this.relatedJobs = res.data;
      });
  }

}
