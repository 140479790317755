/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./related-jobs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/novo-elements/novo-elements.ngfactory";
import * as i3 from "novo-elements";
import * as i4 from "@angular/common";
import * as i5 from "./related-jobs.component";
import * as i6 from "@angular/router";
import * as i7 from "../services/search/search.service";
var styles_RelatedJobsComponent = [i0.styles];
var RenderType_RelatedJobsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelatedJobsComponent, data: {} });
export { RenderType_RelatedJobsComponent as RenderType_RelatedJobsComponent };
function View_RelatedJobsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "novo-loading", [], [[8, "className", 0]], null, null, i2.View_ɵq_0, i2.RenderType_ɵq)), i1.ɵdid(1, 49152, null, 0, i3.ɵq, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).theme || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_RelatedJobsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "job"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openJob(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ", "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.address.city; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.address.state; _ck(_v, 6, 0, currVal_2); }); }
function View_RelatedJobsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "jobs-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RelatedJobsComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i4.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.relatedJobs, 0, 5)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RelatedJobsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 31, "div", [["class", "related-jobs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "date-added"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Added ", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "job-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "job-category"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ", "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" | ", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "apply-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "jobs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "jobs-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["Related ", " Jobs"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedJobsComponent_1)), i1.ɵdid(24, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelatedJobsComponent_2)), i1.ɵdid(26, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 4, "div", [["class", "related-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewAllJobs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["View all jobs in this category"])), (_l()(), i1.ɵeld(32, 0, null, null, 0, "i", [["class", "bhi-arrow-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.loading; _ck(_v, 24, 0, currVal_7); var currVal_8 = !_co.loading; _ck(_v, 26, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.job.dateLastPublished, "MM/dd/yy")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.job.title; _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.job.publishedCategory == null) ? null : _co.job.publishedCategory.name); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.job.address.city; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.job.address.state; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.job.employmentType; _ck(_v, 16, 0, currVal_5); var currVal_6 = ((_co.job.publishedCategory == null) ? null : _co.job.publishedCategory.name); _ck(_v, 22, 0, currVal_6); }); }
export function View_RelatedJobsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-related-jobs", [], null, null, null, View_RelatedJobsComponent_0, RenderType_RelatedJobsComponent)), i1.ɵdid(1, 114688, null, 0, i5.RelatedJobsComponent, [i6.Router, i7.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedJobsComponentNgFactory = i1.ɵccf("app-related-jobs", i5.RelatedJobsComponent, View_RelatedJobsComponent_Host_0, { job: "job" }, { onApply: "onApply" }, []);
export { RelatedJobsComponentNgFactory as RelatedJobsComponentNgFactory };
