<div class="related-jobs">
  <div class="header">
    <span class="date-added">Added {{ job.dateLastPublished | date:'MM/dd/yy'}}</span>
    <span class="job-title">{{ job.title }}</span>
    <span class="job-category">{{ job.publishedCategory?.name }}</span>

    <div class="location">
      <span>{{ job.address.city }}, </span>
      <span>{{ job.address.state }}</span>
      <span> | {{ job.employmentType }}</span>
    </div>

    <button class="apply-button" (click)="apply()">Apply</button>

    <hr>

  </div>

  <div class="jobs">
    <div class='jobs-header'>Related {{ job.publishedCategory?.name }} Jobs</div>
    <novo-loading *ngIf="loading"></novo-loading>
    <div class="jobs-list" *ngIf="!loading">
      <div class="job" *ngFor="let relatedJob of relatedJobs | slice:0:5">
        <a (click)="openJob(relatedJob.id)">{{ relatedJob.title }}</a>
        <span>{{ relatedJob.address.city }}, </span>
        <span>{{ relatedJob.address.state }}</span>
      </div>
    </div>
  </div>

  <hr>

  <div class="related-link">
    <a (click)="viewAllJobs()">
      <span>View all jobs in this category</span>
      <i class="bhi-arrow-right"></i>
    </a>
  </div>
</div>