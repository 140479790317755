import { OnChanges, Renderer2 } from '@angular/core';
import { SettingsService } from '../services/settings/settings.service';
import { SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
var StructuredSeoComponent = /** @class */ (function () {
    function StructuredSeoComponent(_renderer2, _document, datePipe) {
        this._renderer2 = _renderer2;
        this._document = _document;
        this.datePipe = datePipe;
    }
    StructuredSeoComponent.prototype.ngOnChanges = function () {
        var jsonObject = {
            '@context': 'https://schema.org/',
            '@type': 'JobPosting',
            'title': this.jobData.title,
            'description': this.jobData.publicDescription,
            'datePosted': this.datePipe.transform(this.jobData.dateLastPublished, 'long'),
            'employmentType': this.jobData.employmentType,
            'hiringOrganization': {
                '@type': 'Organization',
                'name': SettingsService.settings.companyName,
                'sameAs': SettingsService.settings.companyUrl,
                'logo': SettingsService.settings.companyLogoPath,
            },
            'jobLocation': {
                '@type': 'Place',
                'address': {
                    '@type': 'PostalAddress',
                    'addressLocality': this.jobData.address.city,
                    'addressRegion': this.jobData.address.state,
                    'postalCode': this.jobData.address.zip,
                },
            },
            'baseSalary': {
                '@type': 'MonetaryAmount',
                'currency': 'USD',
                'value': {
                    '@type': 'QuantitativeValue',
                    'value': this.jobData.salary,
                    'unitText': this.salaryUnit,
                },
            },
        };
        var s = this._renderer2.createElement('script');
        s.type = "application/ld+json";
        s.text = JSON.stringify(jsonObject);
        if (SettingsService.isServer) {
            this._renderer2.appendChild(this._document.body, s);
        }
    };
    Object.defineProperty(StructuredSeoComponent.prototype, "salaryUnit", {
        get: function () {
            var unit;
            switch (this.jobData.salaryUnit) {
                case 'Per Hour':
                    unit = 'HOUR';
                    break;
                case 'Per Day':
                    unit = 'DAY';
                    break;
                default:
                    unit = 'YEAR';
                    break;
            }
            return unit;
        },
        enumerable: true,
        configurable: true
    });
    return StructuredSeoComponent;
}());
export { StructuredSeoComponent };
