<div class="home page-template-default page page-id-7 custom-background et_divi_builder et_button_no_icon et_pb_button_helper_class et_fixed_nav et_show_nav et_cover_background et_secondary_nav_enabled et_secondary_nav_two_panels et_pb_gutter windows et_pb_gutters3 et_primary_nav_dropdown_animation_expand et_secondary_nav_dropdown_animation_fade et_pb_footer_columns3 et_header_style_left et_pb_svg_logo et_pb_pagebuilder_layout et_right_sidebar et_divi_theme et_minified_css">
    <div id="page-container" class="header">
       <div id="top-header">
          <div class="container clearfix">
             <div id="et-info">
                <span id="et-info-phone">480.551.1311</span>
                <a href="mailto:web_contact@acclivityhealthcare.com"><span id="et-info-email">web_contact@acclivityhealthcare.com</span></a>
                <div style="float:right;">
                   <ul class="et-social-icons">
                      <li class="et-social-icon et-social-facebook">
                         <a href="https://www.facebook.com/AcclivityHealthcare" class="icon" target="_blank">
                         <span>Facebook</span>
                         </a>
                      </li>
                      <li class="et-social-icon et-social-twitter">
                         <a href="https://twitter.com/acclivitytweets" class="icon" target="_blank">
                         <span>Twitter</span>
                         </a>
                      </li>
                      <li class="et-social-icon et-social-linkedin">
                         <a href="https://www.linkedin.com/company/acclivity-healthcare" class="icon" target="_blank">
                         <span>Linkedin</span>
                         </a>
                      </li>
                   </ul>
                </div>
             </div>
             <!-- #et-info -->
             <div id="et-secondary-menu">
                <div class="et_duplicate_social_icons">
                   <ul class="et-social-icons">
                      <li class="et-social-icon et-social-facebook">
                         <a href="https://www.facebook.com/AcclivityHealthcare" class="icon" target="_blank">
                         <span>Facebook</span>
                         </a>
                      </li>
                      <li class="et-social-icon et-social-twitter">
                         <a href="https://twitter.com/acclivitytweets" class="icon" target="_blank">
                         <span>Twitter</span>
                         </a>
                      </li>
                      <li class="et-social-icon et-social-linkedin">
                         <a href="https://www.linkedin.com/company/acclivity-healthcare" class="icon" target="_blank"><span>Linkedin</span>
                         </a>
                      </li>
                   </ul>
                </div>
             </div>
             <!-- #et-secondary-menu -->
          </div>
          <!-- .container -->
       </div>
       <!-- #top-header -->
       <header id="main-header" data-height-onload="80" style="top: 36px">
          <div class="container clearfix et_menu_container">
             <div class="logo_container">
                <span class="logo_helper"></span>
                <a href="https://www.acclivityhealthcare.com/">
                  <img src="https://www.acclivityhealthcare.com/wp-content/uploads/2018/10/Acclivity-Logo.svg" alt="Acclivity Healthcare" id="logo" data-height-percentage="80" />
                </a>
             </div>
             <div id="et-top-navigation" data-height="80" data-fixed-height="60">
                <nav id="top-menu-nav">
                    <!-- desktop nav -->
                    <ul id="top-menu" class="nav desktop-nav">
                      <li id="menu-item-34" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-34 active"><a href="#">Search Jobs</a></li>
                      <li id="menu-item-269" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-269">
                          <a href="https://www.acclivityhealthcare.com/services/">Services</a>
                          <ul class="sub-menu">
                            <li id="menu-item-397" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-397"><a href="https://www.acclivityhealthcare.com/services/">All Services</a></li>
                            <li id="menu-item-288" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-288"><a href="https://www.acclivityhealthcare.com/services/clinical-staffing/">Clinical Staffing</a></li>
                            <li id="menu-item-293" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-293"><a href="https://www.acclivityhealthcare.com/services/administrative-staffing/">Administrative Staffing</a></li>
                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337"><a href="https://www.acclivityhealthcare.com/services/direct-hire/">Direct Hire</a></li>
                            <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336"><a href="https://www.acclivityhealthcare.com/services/background-screening/">Background Screening</a></li>
                            <li id="menu-item-335" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-335"><a href="https://www.acclivityhealthcare.com/services/payroll-service/">Payroll Service</a></li>
                          </ul>
                      </li>
                      <li id="menu-item-398" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-398">
                          <a href="https://www.acclivityhealthcare.com/about-us/">About Us</a>
                          <ul class="sub-menu">
                            <li id="menu-item-396" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-396"><a href="https://www.acclivityhealthcare.com/about-us/">About Us</a></li>
                            <li id="menu-item-391" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-391"><a href="https://www.acclivityhealthcare.com/referral-bonus-program/">Referral Bonus Program</a></li>
                            <li id="menu-item-547" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-547"><a href="https://www.acclivityhealthcare.com/testimonials/">Testimonials</a></li>
                            <li id="menu-item-587" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-587"><a href="https://www.acclivityhealthcare.com/news-blog/">Blog and Newsletter Archive</a></li>
                          </ul>
                      </li>
                      <li id="menu-item-228" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-228"><a href="https://www.acclivityhealthcare.com/frequently-asked-questions/">FAQ</a></li>
                      <li id="menu-item-390" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-390"><a href="https://www.acclivityhealthcare.com/request-a-quote/">Request Quote</a></li>
                      <li id="menu-item-343" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-343"><a href="https://www.acclivityhealthcare.com/contact-us/">Contact Us</a></li>
                    </ul>
                    <!-- mobile nav -->
                    <div id="mobileNav" class="mobile-nav" [ngClass]="{'open': isMobileNavOpen}">
                      <ul>
                        <li>
                          <a href="https://www.acclivityhealthcare.com/search-jobs/">Search Jobs</a>
                        </li>
                        <li class="sub-header">
                          <a href="https://www.acclivityhealthcare.com/services/">Services</a>

                          <ul>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/services/">All Services</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/services/clinical-staffing/">Clinical Staffing</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/services/administrative-staffing/">Administrative Staffing</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/services/direct-hire/">Direct Hire</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/services/background-screening/">Background Screening</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/services/payroll-service/">Payroll Service</a>
                            </li>
                          </ul>
                        </li>

                        <li class="sub-header">
                          <a href="https://www.acclivityhealthcare.com/about-us/">About Us</a>

                          <ul>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/about-us/">About Us</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/referral-bonus-program/">Referral Bonus Program</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/testimonials/">Testimonials</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/news-blog/">Blog</a>
                            </li>
                            <li>
                              <a href="https://www.acclivityhealthcare.com/newsletter/">Newsletter Archive</a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a href="https://www.acclivityhealthcare.com/search-jobs/">FAQ</a>
                        </li>

                        <li>
                          <a href="https://www.acclivityhealthcare.com/search-jobs/">Request Quote</a>
                        </li>

                        <li>
                          <a href="https://www.acclivityhealthcare.com/search-jobs/">Contact Us</a>
                        </li>
                      </ul>
                    </div>

                </nav>
                <div id="et_mobile_nav_menu">
                   <div class="mobile_nav open">
                      <span class="select_page">Select Page</span>
                      <span class="mobile_menu_bar mobile_menu_bar_toggle" (click)="toggleMobileNav()"></span>
                   </div>
                </div>
             </div>
             <!-- #et-top-navigation -->
          </div>
          <!-- .container -->
       </header>
       <!-- #main-header -->
       <div id="et-main-area">
          <main></main>
          <script src="../lib/ng-file-upload/ng-file-upload.js"></script>
       </div>
       <!-- #page-container -->
    </div>
</div>
